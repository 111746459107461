define("discourse/plugins/discourse-chatbot/discourse/components/chatbot-launch", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/url", "discourse/models/composer", "discourse/models/user", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _ajax, _url, _composer3, _user, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showChatbotButton}}
    <DButton
      {{did-insert this.getBotUser}}
      @id="chatbot-btn"
      @class="btn-primary chatbot-btn"
      @action={{action this.startChatting}}
    >
      {{#if this.chatbotLaunchUseAvatar}}
        {{avatar this.botUser imageSize="medium"}}
      {{else}}
        {{d-icon this.chatbotLaunchIcon}}
      {{/if}}
      <label class="d-button-label">{{i18n "chatbot.title_capitalized"}}</label>
    </DButton>
  {{/if}}
  */
  {
    "id": "xcQmg6Ll",
    "block": "[[[41,[30,0,[\"showChatbotButton\"]],[[[1,\"  \"],[8,[39,1],[[4,[38,3],[[30,0,[\"getBotUser\"]]],null]],[[\"@id\",\"@class\",\"@action\"],[\"chatbot-btn\",\"btn-primary chatbot-btn\",[28,[37,2],[[30,0],[30,0,[\"startChatting\"]]],null]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"chatbotLaunchUseAvatar\"]],[[[1,\"      \"],[1,[28,[35,4],[[30,0,[\"botUser\"]]],[[\"imageSize\"],[\"medium\"]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,5],[[30,0,[\"chatbotLaunchIcon\"]]],null]],[1,\"\\n\"]],[]]],[1,\"    \"],[10,\"label\"],[14,0,\"d-button-label\"],[12],[1,[28,[35,7],[\"chatbot.title_capitalized\"],null]],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-button\",\"action\",\"did-insert\",\"avatar\",\"d-icon\",\"label\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-chatbot/discourse/components/chatbot-launch.hbs",
    "isStrictMode": false
  });
  class ContentLanguageDiscovery extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "chat", [_service.inject]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "composer", [_service.inject]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "site", [_service.inject]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "botUser", [_tracking.tracked], function () {
      return null;
    }))();
    #botUser = (() => (dt7948.i(this, "botUser"), void 0))();
    get showChatbotButton() {
      const baseRoute = this.router.currentRouteName.split(".")[0];
      return this.currentUser && this.siteSettings.chatbot_enabled && this.currentUser.chatbot_access && (baseRoute === "discovery" || !this.site.mobileView && baseRoute === "topic") && this.siteSettings.chatbot_quick_access_talk_button !== "off" && (this.siteSettings.chat_enabled && this.siteSettings.chatbot_permitted_in_chat || this.siteSettings.chatbot_permitted_in_private_messages && this.siteSettings.chatbot_quick_access_talk_button === "personal message");
    }
    getBotUser() {
      _user.default.findByUsername(this.siteSettings.chatbot_bot_user, {}).then(user => {
        this.botUser = user;
      });
    }
    static #_8 = (() => dt7948.n(this.prototype, "getBotUser", [_object.action]))();
    get chatbotLaunchUseAvatar() {
      return this.siteSettings.chatbot_quick_access_talk_button_bot_icon === "";
    }
    get chatbotLaunchIcon() {
      return this.siteSettings.chatbot_quick_access_talk_button_bot_icon;
    }
    async startChatting() {
      let result = {};
      if (this.siteSettings.chatbot_quick_access_bot_kicks_off) {
        result = await (0, _ajax.ajax)("/chatbot/start_bot_convo", {
          type: "POST"
        });
      }
      if (this.siteSettings.chatbot_quick_access_talk_button === "personal message") {
        if (this.siteSettings.chatbot_quick_access_bot_kicks_off) {
          _url.default.redirectTo(`/t/${result.topic_id}`);
        } else {
          this.composer.focusComposer({
            fallbackToNewTopic: true,
            openOpts: {
              action: _composer3.default.PRIVATE_MESSAGE,
              recipients: this.siteSettings.chatbot_bot_user,
              topicTitle: _I18n.default.t("chatbot.pm_prefix"),
              archetypeId: "private_message",
              draftKey: _composer3.default.NEW_PRIVATE_MESSAGE_KEY,
              hasGroups: false,
              warningsDisabled: true
            }
          });
        }
      } else {
        this.chat.upsertDmChannel({
          usernames: [this.siteSettings.chatbot_bot_user, this.currentUser.username]
        }).then(chatChannel => {
          this.router.transitionTo("chat.channel", ...chatChannel.routeModels);
        });
      }
    }
    static #_9 = (() => dt7948.n(this.prototype, "startChatting", [_object.action]))();
  }
  _exports.default = ContentLanguageDiscovery;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ContentLanguageDiscovery);
});