define("discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-followers", ["exports", "@ember/object", "@ember/object/computed", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _object, _computed, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubFollowers extends _object.default {
    static #_ = (() => dt7948.g(this.prototype, "hasFollowers", [(0, _computed.notEmpty)("followers")]))();
    #hasFollowers = (() => (dt7948.i(this, "hasFollowers"), void 0))();
    loadMore() {
      if (!this.loadMoreUrl || this.total <= this.followers.length) {
        return;
      }
      this.set("loadingMore", true);
      return (0, _ajax.ajax)(this.loadMoreUrl).then(response => {
        if (response) {
          this.followers.pushObjects(response.followers);
          this.setProperties({
            loadMoreUrl: response.meta.load_more_url,
            loadingMore: false
          });
        }
      }).catch(_ajaxError.popupAjaxError);
    }
  }
  ActivityPubFollowers.reopenClass({
    load(category, params) {
      const queryParams = new URLSearchParams();
      if (params.order) {
        queryParams.set("order", params.order);
      }
      if (params.asc) {
        queryParams.set("asc", params.asc);
      }
      const path = `/ap/category/${category.id}/followers`;
      let url = `${path}.json`;
      if (queryParams.size) {
        url += `?${queryParams.toString()}`;
      }
      return (0, _ajax.ajax)(url).then(response => ({
        category,
        ...response
      })).catch(_ajaxError.popupAjaxError);
    }
  });
  var _default = _exports.default = ActivityPubFollowers;
});