import { template as template_db648dd61dfe4b6a9657c9b2e3581300 } from "@ember/template-compiler";
const FKText = template_db648dd61dfe4b6a9657c9b2e3581300(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
