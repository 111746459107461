define("discourse/plugins/discourse-chatbot/discourse/templates/connectors/category-custom-settings/set-chatbot", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <section>
    <h3> {{i18n "chatbot.category.settings_label"}}</h3>
  
    <section class="field">
      {{html-safe (i18n "chatbot.category.auto_response_additional_prompt")}}
      {{input
        type="textarea"
        value=category.custom_fields.chatbot_auto_response_additional_prompt
      }}
      {{!-- {{i18n "chatbot.category.auto_response_additional_prompt"}} --}}
  
    </section>
  
  </section>
  */
  {
    "id": "UFrFJYGO",
    "block": "[[[10,\"section\"],[12],[1,\"\\n  \"],[10,\"h3\"],[12],[1,\" \"],[1,[28,[35,2],[\"chatbot.category.settings_label\"],null]],[13],[1,\"\\n\\n  \"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n    \"],[1,[28,[35,3],[[28,[37,2],[\"chatbot.category.auto_response_additional_prompt\"],null]],null]],[1,\"\\n    \"],[1,[28,[35,4],null,[[\"type\",\"value\"],[\"textarea\",[30,0,[\"category\",\"custom_fields\",\"chatbot_auto_response_additional_prompt\"]]]]]],[1,\"\\n\"],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `category` property path was used in the `discourse/plugins/discourse-chatbot/discourse/templates/connectors/category-custom-settings/set-chatbot.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.category}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"section\",\"h3\",\"i18n\",\"html-safe\",\"input\"]]",
    "moduleName": "discourse/plugins/discourse-chatbot/discourse/templates/connectors/category-custom-settings/set-chatbot.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});