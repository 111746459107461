define("discourse/plugins/discourse-post-voting/discourse/components/post-voting-answer-button", ["exports", "@glimmer/component", "@ember/service", "discourse/components/d-button", "discourse/helpers/concat-class", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _dButton, _concatClass, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PostVotingAnswerButton extends _component.default {
    static shouldRender(args) {
      return args.state.canCreatePost && args.post.post_number === 1;
    }
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    get showLabel() {
      return this.site.desktopView;
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          class={{concatClass
            "post-action-menu__post-voting-answer"
            "reply"
            (if this.showLabel "create fade-out")
          }}
          ...attributes
          @action={{@buttonActions.replyToPost}}
          @icon="reply"
          @label={{if this.showLabel "post_voting.topic.answer.label"}}
          @title="post_voting.topic.answer.help"
        />
      
    */
    {
      "id": "vkSoIcWn",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[16,0,[28,[32,1],[\"post-action-menu__post-voting-answer\",\"reply\",[52,[30,0,[\"showLabel\"]],\"create fade-out\"]],null]],[17,1]],[[\"@action\",\"@icon\",\"@label\",\"@title\"],[[30,2,[\"replyToPost\"]],\"reply\",[52,[30,0,[\"showLabel\"]],\"post_voting.topic.answer.label\"],\"post_voting.topic.answer.help\"]],null],[1,\"\\n  \"]],[\"&attrs\",\"@buttonActions\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-post-voting/discourse/components/post-voting-answer-button.js",
      "scope": () => [_dButton.default, _concatClass.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = PostVotingAnswerButton;
});