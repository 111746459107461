define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-post-object-type-dropdown", ["exports", "@ember/object", "@ember-decorators/component", "I18n", "select-kit/components/combo-box"], function (_exports, _object, _component, _I18n, _comboBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ActivityPubPostObjectTypeDropdown = dt7948.c(class ActivityPubPostObjectTypeDropdown extends _comboBox.default {
    nameProperty = "label";
    get content() {
      return [{
        id: "Note",
        label: _I18n.default.t("discourse_activity_pub.post_object_type.label.note"),
        title: _I18n.default.t("discourse_activity_pub.post_object_type.description.note")
      }, {
        id: "Article",
        label: _I18n.default.t("discourse_activity_pub.post_object_type.label.article"),
        title: _I18n.default.t("discourse_activity_pub.post_object_type.description.article")
      }];
    }
    static #_ = (() => dt7948.n(this.prototype, "content", [_object.computed]))();
  }, [(0, _component.classNames)("activity-pub-post-object-type-dropdown")]);
  var _default = _exports.default = ActivityPubPostObjectTypeDropdown;
});