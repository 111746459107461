define("discourse/plugins/discourse-activity-pub/discourse/templates/admin-plugins-activity-pub", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#admin-nav}}
    <li>
      <LinkTo
        @route="adminPlugins.activityPub.actor"
        @query={{hash model_type="category"}}
      >
        <span>{{i18n "admin.discourse_activity_pub.actor.category.label"}}</span>
      </LinkTo>
    </li>
    <li>
      <LinkTo
        @route="adminPlugins.activityPub.actor"
        @query={{hash model_type="tag"}}
      >
        <span>{{i18n "admin.discourse_activity_pub.actor.tag.label"}}</span>
      </LinkTo>
    </li>
    <li class="activity-pub-add-actor">
      <LinkTo
        @route="adminPlugins.activityPub.actorShow"
        @model={{this.newActor}}
      >
        {{d-icon "plus"}}
        <span>{{i18n "admin.discourse_activity_pub.actor.add.label"}}</span>
      </LinkTo>
    </li>
  {{/admin-nav}}
  
  <div class="admin-container">
    {{outlet}}
  </div>
  */
  {
    "id": "j6dSeOey",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,\"li\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@route\",\"@query\"],[\"adminPlugins.activityPub.actor\",[28,[37,3],null,[[\"model_type\"],[\"category\"]]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,1],[12],[1,[28,[35,5],[\"admin.discourse_activity_pub.actor.category.label\"],null]],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"li\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@route\",\"@query\"],[\"adminPlugins.activityPub.actor\",[28,[37,3],null,[[\"model_type\"],[\"tag\"]]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,1],[12],[1,[28,[35,5],[\"admin.discourse_activity_pub.actor.tag.label\"],null]],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"li\"],[14,0,\"activity-pub-add-actor\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"adminPlugins.activityPub.actorShow\",[30,0,[\"newActor\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,6],[\"plus\"],null]],[1,\"\\n      \"],[10,1],[12],[1,[28,[35,5],[\"admin.discourse_activity_pub.actor.add.label\"],null]],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[10,0],[14,0,\"admin-container\"],[12],[1,\"\\n  \"],[46,[28,[37,9],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"admin-nav\",\"li\",\"link-to\",\"hash\",\"span\",\"i18n\",\"d-icon\",\"div\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-activity-pub/discourse/templates/admin-plugins-activity-pub.hbs",
    "isStrictMode": false
  });
});