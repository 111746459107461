define("discourse/plugins/discourse-activity-pub/discourse/controllers/preferences-activity-pub", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "I18n", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-authorization"], function (_exports, _tracking, _controller, _object, _computed, _service, _I18n, _activityPubAuthorization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PreferencesActivityPubController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "authorizations", [_tracking.tracked], function () {
      return null;
    }))();
    #authorizations = (() => (dt7948.i(this, "authorizations"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "error", [_tracking.tracked]))();
    #error = (() => (dt7948.i(this, "error"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "hasAuthorizations", [(0, _computed.notEmpty)("authorizations")]))();
    #hasAuthorizations = (() => (dt7948.i(this, "hasAuthorizations"), void 0))();
    queryParams = ["error"];
    clearError() {
      this.error = null;
    }
    showError() {
      if (this.error) {
        this.dialog.alert({
          title: _I18n.default.t("user.discourse_activity_pub.authorize_error.title"),
          message: this.error,
          didConfirm: () => this.clearError(),
          didCancel: () => this.clearError()
        });
      }
    }
    remove(authorization) {
      this.dialog.yesNoConfirm({
        message: _I18n.default.t("user.discourse_activity_pub.authorization.confirm_remove", {
          handle: authorization.actor?.handle
        }),
        didConfirm: () => {
          _activityPubAuthorization.default.remove(authorization.id).then(result => {
            if (result.success) {
              this.authorizations = this.authorizations.filter(a => {
                return a.id !== authorization.id;
              });
            }
          });
        }
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "remove", [_object.action]))();
  }
  _exports.default = PreferencesActivityPubController;
});