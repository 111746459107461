define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-category-chooser", ["exports", "@ember-decorators/component", "select-kit/components/category-chooser", "select-kit/components/select-kit", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-actor"], function (_exports, _component, _categoryChooser, _selectKit, _activityPubActor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ActivityPubCategoryChooser = dt7948.c(class ActivityPubCategoryChooser extends _categoryChooser.default {
    categoriesByScope() {
      return super.categoriesByScope().filter(category => {
        if (category.read_restricted) {
          return false;
        }
        const actor = _activityPubActor.default.findByModel(category, "category");
        if (this.selectKit.options.hasActor) {
          return !!actor;
        } else {
          return !actor;
        }
      });
    }
  }, [(0, _component.classNames)("activity-pub-category-chooser"), (0, _selectKit.selectKitOptions)({
    allowUncategorized: false
  })]);
  var _default = _exports.default = ActivityPubCategoryChooser;
});