import { template as template_b6d9b942e6ca4af4ad160ee90e0f873f } from "@ember/template-compiler";
const FKControlMenuContainer = template_b6d9b942e6ca4af4ad160ee90e0f873f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
