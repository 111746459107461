import { template as template_78dd3987aa444b2b90e1b2b3cefc946e } from "@ember/template-compiler";
const FKLabel = template_78dd3987aa444b2b90e1b2b3cefc946e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
