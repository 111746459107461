define("discourse/plugins/discourse-post-voting/discourse/templates/connectors/category-custom-settings/post-voting-category-settings", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <h3>{{i18n "category.post_voting_settings_heading"}}</h3>
  <section class="field">
    <label>
      <Input
        id="create-as-post-voting-default"
        @type="checkbox"
        @checked={{this.category.custom_fields.create_as_post_voting_default}}
      />
      {{i18n "category.create_as_post_voting_default"}}
    </label>
    <label>
      <Input
        id="only-post-voting-in-this-category"
        @type="checkbox"
        @checked={{this.category.custom_fields.only_post_voting_in_this_category}}
      />
      {{i18n "category.only_post_voting_in_this_category"}}
    </label>
  </section>
  */
  {
    "id": "R0+S5f4a",
    "block": "[[[10,\"h3\"],[12],[1,[28,[35,1],[\"category.post_voting_settings_heading\"],null]],[13],[1,\"\\n\"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n  \"],[10,\"label\"],[12],[1,\"\\n    \"],[8,[39,4],[[24,1,\"create-as-post-voting-default\"]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"category\",\"custom_fields\",\"create_as_post_voting_default\"]]]],null],[1,\"\\n    \"],[1,[28,[35,1],[\"category.create_as_post_voting_default\"],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"label\"],[12],[1,\"\\n    \"],[8,[39,4],[[24,1,\"only-post-voting-in-this-category\"]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"category\",\"custom_fields\",\"only_post_voting_in_this_category\"]]]],null],[1,\"\\n    \"],[1,[28,[35,1],[\"category.only_post_voting_in_this_category\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"h3\",\"i18n\",\"section\",\"label\",\"input\"]]",
    "moduleName": "discourse/plugins/discourse-post-voting/discourse/templates/connectors/category-custom-settings/post-voting-category-settings.hbs",
    "isStrictMode": false
  });
});