define("discourse/plugins/discourse-activity-pub/discourse/components/modal/activity-pub-follow", ["exports", "@ember/component", "@glimmer/component", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @closeModal={{@closeModal}}
    @title={{this.title}}
    class="activity-pub-follow-modal"
  >
    <:body>
      <div class="activity-pub-follow-controls">
        <ActivityPubFollowDomain @actor={{@model.actor}} />
        <label class="activity-pub-handle-label">
          {{i18n "discourse_activity_pub.handle.label"}}
        </label>
        <ActivityPubHandle @actor={{@model.actor}} />
        <div class="activity-pub-handle-description">
          {{i18n "discourse_activity_pub.handle.description"}}
        </div>
      </div>
    </:body>
  </DModal>
  */
  {
    "id": "gaps2NvA",
    "block": "[[[8,[39,0],[[24,0,\"activity-pub-follow-modal\"]],[[\"@closeModal\",\"@title\"],[[30,1],[30,0,[\"title\"]]]],[[\"body\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"activity-pub-follow-controls\"],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@actor\"],[[30,2,[\"actor\"]]]],null],[1,\"\\n      \"],[10,\"label\"],[14,0,\"activity-pub-handle-label\"],[12],[1,\"\\n        \"],[1,[28,[35,5],[\"discourse_activity_pub.handle.label\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,6],null,[[\"@actor\"],[[30,2,[\"actor\"]]]],null],[1,\"\\n      \"],[10,0],[14,0,\"activity-pub-handle-description\"],[12],[1,\"\\n        \"],[1,[28,[35,5],[\"discourse_activity_pub.handle.description\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\":body\",\"div\",\"activity-pub-follow-domain\",\"label\",\"i18n\",\"activity-pub-handle\"]]",
    "moduleName": "discourse/plugins/discourse-activity-pub/discourse/components/modal/activity-pub-follow.hbs",
    "isStrictMode": false
  });
  class ActivityPubFollow extends _component2.default {
    get title() {
      return _I18n.default.t("discourse_activity_pub.follow.title", {
        actor: this.args.model.actor.name
      });
    }
  }
  _exports.default = ActivityPubFollow;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ActivityPubFollow);
});