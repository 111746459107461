define("discourse/plugins/discourse-activity-pub/discourse/routes/activity-pub-actor-followers", ["exports", "@ember/array", "@ember/service", "discourse/routes/discourse", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-actor"], function (_exports, _array, _service, _discourse, _activityPubActor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubActorFollowers extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    queryParams = {
      order: {
        refreshModel: true
      },
      asc: {
        refreshModel: true
      }
    };
    afterModel(_, transition) {
      const actor = this.modelFor("activityPub.actor");
      if (!actor.can_admin && !this.site.activity_pub_publishing_enabled) {
        this.router.replaceWith("/404");
        return;
      }
      return _activityPubActor.default.list(actor.id, transition.to.queryParams, "followers").then(response => this.setProperties(response));
    }
    setupController(controller) {
      controller.setProperties({
        actor: this.modelFor("activityPub.actor"),
        actors: (0, _array.A)(this.actors || []),
        loadMoreUrl: this.meta?.load_more_url,
        total: this.meta?.total
      });
    }
  }
  _exports.default = ActivityPubActorFollowers;
});