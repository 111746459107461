define("discourse/plugins/discourse-chatbot/discourse/components/composer-raiser", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "discourse/models/composer", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _composer3, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isBotConversation}}
    <div {{did-insert this.raiseComposer}} />
  {{/if}}
  */
  {
    "id": "bOm+uXRA",
    "block": "[[[41,[30,0,[\"isBotConversation\"]],[[[1,\"  \"],[11,0],[4,[38,2],[[30,0,[\"raiseComposer\"]]],null],[12],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"div\",\"did-insert\"]]",
    "moduleName": "discourse/plugins/discourse-chatbot/discourse/components/composer-raiser.hbs",
    "isStrictMode": false
  });
  class ComposerRaiserCompopnent extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "site", [_service.inject]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "composer", [_service.inject]))();
    #composer = (() => (dt7948.i(this, "composer"), void 0))();
    BOT_USER_ID = (() => -4)();
    raiseComposer() {
      if (!(this.site.mobileView && this.currentUser.custom_fields.chatbot_user_prefs_disable_quickchat_pm_composer_popup_mobile) && this.args.model.current_post_number === 1) {
        this.composer.focusComposer({
          fallbackToNewTopic: true,
          openOpts: {
            action: _composer3.default.REPLY,
            recipients: this.siteSettings.chatbot_bot_user,
            draftKey: this.args.model.get("draft_key"),
            topic: this.args.model,
            hasGroups: false,
            warningsDisabled: true
          }
        });
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "raiseComposer", [_object.action]))();
    get isBotConversation() {
      return this.currentUser && this.args.model.archetype === "private_message" && this.args.model.user_id === this.BOT_USER_ID;
    }
  }
  _exports.default = ComposerRaiserCompopnent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ComposerRaiserCompopnent);
});