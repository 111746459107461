define("discourse/plugins/discourse-activity-pub/discourse/routes/activity-pub-actor-follows", ["exports", "@ember/array", "discourse/routes/discourse", "discourse-common/utils/decorators", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-actor"], function (_exports, _array, _discourse, _decorators, _activityPubActor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubActorFollows extends _discourse.default {
    queryParams = {
      order: {
        refreshModel: true
      },
      asc: {
        refreshModel: true
      }
    };
    afterModel(_, transition) {
      const actor = this.modelFor("activityPub.actor");
      if (!actor.can_admin) {
        this.router.replaceWith("/404");
        return;
      }
      return _activityPubActor.default.list(actor.id, transition.to.queryParams, "follows").then(response => this.setProperties(response));
    }
    setupController(controller) {
      controller.setProperties({
        actor: this.modelFor("activityPub.actor"),
        actors: (0, _array.A)(this.actors || []),
        loadMoreUrl: this.meta?.load_more_url,
        total: this.meta?.total
      });
    }
    activate() {
      this.messageBus.subscribe("/activity-pub", this.handleMessage);
    }
    deactivate() {
      this.messageBus.unsubscribe("/activity-pub", this.handleMessage);
    }
    handleMessage(data) {
      const model = data.model;
      const actor = this.modelFor("activityPub.actor");
      if (model && model.type === "category" && model.id === actor.id) {
        this.refresh();
      }
    }
    static #_ = (() => dt7948.n(this.prototype, "handleMessage", [_decorators.bind]))();
  }
  _exports.default = ActivityPubActorFollows;
});