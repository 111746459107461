define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-visibility-dropdown", ["exports", "@ember/object", "@ember/object/computed", "@ember/runloop", "@ember-decorators/component", "I18n", "select-kit/components/combo-box"], function (_exports, _object, _computed, _runloop, _component, _I18n, _comboBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ActivityPubVisibilityDropdown = dt7948.c(class ActivityPubVisibilityDropdown extends _comboBox.default {
    static #_ = (() => dt7948.g(this.prototype, "fullTopicPublication", [(0, _computed.equal)("publicationType", "full_topic")]))();
    #fullTopicPublication = (() => (dt7948.i(this, "fullTopicPublication"), void 0))();
    nameProperty = "label";
    get content() {
      return [{
        id: "private",
        label: _I18n.default.t("discourse_activity_pub.visibility.label.private"),
        title: _I18n.default.t("discourse_activity_pub.visibility.description.private", {
          object_type: this.objectType
        })
      }, {
        id: "public",
        label: _I18n.default.t("discourse_activity_pub.visibility.label.public"),
        title: _I18n.default.t("discourse_activity_pub.visibility.description.public", {
          object_type: this.objectType
        })
      }];
    }
    static #_2 = (() => dt7948.n(this.prototype, "content", [_object.computed]))();
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      if (this.fullTopicPublication) {
        this.set("value", "public");
      }
      (0, _runloop.schedule)("afterRender", () => {
        this.set("selectKit.options.disabled", this.fullTopicPublication);
      });
    }
  }, [(0, _component.classNames)("activity-pub-visibility-dropdown")]);
  var _default = _exports.default = ActivityPubVisibilityDropdown;
});