define("discourse/plugins/discourse-post-voting/discourse/components/post-voting-comments", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="post-voting-comments">
    {{#each this.comments as |comment|}}
      <PostVotingComment
        @comment={{comment}}
        @removeComment={{this.removeComment}}
        @updateComment={{this.updateComment}}
        @vote={{this.vote}}
        @removeVote={{this.removeVote}}
        @disabled={{this.disabled}}
      />
    {{/each}}
  
    {{#if @canCreatePost}}
      <PostVotingCommentsMenu
        @id={{@post.id}}
        @postNumber={{@post.post_number}}
        @moreCommentCount={{this.moreCommentCount}}
        @lastCommentId={{this.lastCommentId}}
        @appendComments={{this.appendComments}}
      />
    {{/if}}
  </div>
  */
  {
    "id": "Vu0ppdlC",
    "block": "[[[10,0],[14,0,\"post-voting-comments\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"comments\"]]],null]],null],null,[[[1,\"    \"],[8,[39,3],null,[[\"@comment\",\"@removeComment\",\"@updateComment\",\"@vote\",\"@removeVote\",\"@disabled\"],[[30,1],[30,0,[\"removeComment\"]],[30,0,[\"updateComment\"]],[30,0,[\"vote\"]],[30,0,[\"removeVote\"]],[30,0,[\"disabled\"]]]],null],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[8,[39,5],null,[[\"@id\",\"@postNumber\",\"@moreCommentCount\",\"@lastCommentId\",\"@appendComments\"],[[30,3,[\"id\"]],[30,3,[\"post_number\"]],[30,0,[\"moreCommentCount\"]],[30,0,[\"lastCommentId\"]],[30,0,[\"appendComments\"]]]],null],[1,\"\\n\"]],[]],null],[13]],[\"comment\",\"@canCreatePost\",\"@post\"],false,[\"div\",\"each\",\"-track-array\",\"post-voting-comment\",\"if\",\"post-voting-comments-menu\"]]",
    "moduleName": "discourse/plugins/discourse-post-voting/discourse/components/post-voting-comments.hbs",
    "isStrictMode": false
  });
  class PostVotingComments extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "comments", [_tracking.tracked], function () {
      return this.args.post.comments;
    }))();
    #comments = (() => (dt7948.i(this, "comments"), void 0))();
    get moreCommentCount() {
      return this.args.post.comments_count - this.comments.length;
    }
    get lastCommentId() {
      return this.comments?.[this.comments.length - 1]?.id ?? 0;
    }
    get disabled() {
      return this.args.post.topic.closed || this.args.post.topic.archived;
    }
    appendComments(comments) {
      this.comments.pushObjects(comments);
    }
    static #_2 = (() => dt7948.n(this.prototype, "appendComments", [_object.action]))();
    removeComment(commentId) {
      const indexToRemove = this.comments.findIndex(comment => comment.id === commentId);
      if (indexToRemove !== -1) {
        const comment = {
          ...this.comments[indexToRemove],
          deleted: true
        };
        this.comments.replace(indexToRemove, 1, [comment]);
        this.args.post.comments_count--;
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "removeComment", [_object.action]))();
    updateComment(comment) {
      const index = this.comments.findIndex(oldComment => oldComment.id === comment.id);
      this.comments.replace(index, 1, [comment]);
    }
    static #_4 = (() => dt7948.n(this.prototype, "updateComment", [_object.action]))();
    vote(commentId) {
      const index = this.comments.findIndex(oldComment => oldComment.id === commentId);
      const comment = this.comments[index];
      const updatedComment = {
        ...comment,
        post_voting_vote_count: comment.post_voting_vote_count + 1,
        user_voted: true
      };
      this.comments.replace(index, 1, [updatedComment]);
    }
    static #_5 = (() => dt7948.n(this.prototype, "vote", [_object.action]))();
    removeVote(commentId) {
      const index = this.comments.findIndex(oldComment => oldComment.id === commentId);
      const comment = this.comments[index];
      const updatedComment = {
        ...comment,
        post_voting_vote_count: comment.post_voting_vote_count - 1,
        user_voted: false
      };
      this.comments.replace(index, 1, [updatedComment]);
    }
    static #_6 = (() => dt7948.n(this.prototype, "removeVote", [_object.action]))();
  }
  _exports.default = PostVotingComments;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PostVotingComments);
});